// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var userForm = Cx.cx([
      "flex",
      "flex-col",
      "gap-4"
    ]);

var formRow = Cx.cx([
      "flex",
      "gap-4"
    ]);

var fieldContainer = Cx.cx([
      "flex",
      "flex-col",
      "flex-1"
    ]);

var profilePicContainer = Cx.cx([
      "flex",
      "items-center",
      "gap-5",
      "max-md:flex-col"
    ]);

var uploadedImage = Cx.cx([
      "[&_img]:h-full",
      "[&_img]:w-full",
      "[&_img]:object-cover",
      "relative",
      "overflow-hidden",
      "rounded-sm",
      "mr-5",
      "size-30"
    ]);

var defaultProfilePic = Cx.cx([
      "w-full",
      "h-full",
      "object-cover"
    ]);

var twoColumn = Cx.cx(["w-1/2"]);

var twoColumnFieldContainer = Cx.cx(["w-1/2"]);

var uploader = Cx.cx([
      "flex",
      "gap-2",
      "items-center",
      "content-center",
      "max-md:justify-center",
      "w-full",
      "mb-5",
      "border",
      "border-dashed",
      "border-gray-300",
      "rounded",
      "font-bold",
      "md:h-44",
      "h-50"
    ]);

export {
  userForm ,
  formRow ,
  fieldContainer ,
  profilePicContainer ,
  uploadedImage ,
  defaultProfilePic ,
  twoColumn ,
  twoColumnFieldContainer ,
  uploader ,
}
/* userForm Not a pure module */
