// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_User from "../../../../../routes/common/Routes_User.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardNavbarCss from "../../../../../styleguide/dashboard/components/navbar/DashboardNavbarCss.res.js";

function UsersDashboardNavbar(props) {
  var id = props.id;
  var selectedCategory = props.selectedCategory;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(A.make, {
                                  href: Routes_User.Dashboard.index,
                                  className: Cx.cx([selectedCategory === "Users" ? DashboardNavbarCss.underlinedLinkItem : DashboardNavbarCss.normalLinkItem]),
                                  children: "Users"
                                }),
                            className: Cx.cx([selectedCategory === "Users" ? DashboardNavbarCss.underlinedLinkContainer : DashboardNavbarCss.linkContainer])
                          }),
                      selectedCategory === "Show" ? (
                          id !== undefined ? JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_User.Dashboard.show(Caml_option.valFromOption(id)),
                                        className: Cx.cx([selectedCategory === "Users" ? DashboardNavbarCss.normalLinkItem : DashboardNavbarCss.underlinedLinkItem]),
                                        children: "View User"
                                      }),
                                  className: DashboardNavbarCss.underlinedLinkContainer
                                }) : null
                        ) : (
                          selectedCategory === "User" && id !== undefined ? JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_User.Dashboard.edit(Caml_option.valFromOption(id)),
                                        className: Cx.cx([selectedCategory === "Users" ? DashboardNavbarCss.normalLinkItem : DashboardNavbarCss.underlinedLinkItem]),
                                        children: "Edit User"
                                      }),
                                  className: DashboardNavbarCss.underlinedLinkContainer
                                }) : null
                        )
                    ],
                    className: DashboardNavbarCss.barContainer
                  }),
              className: DashboardNavbarCss.barWrapper
            });
}

var Css;

var make = UsersDashboardNavbar;

export {
  Css ,
  make ,
}
/* A Not a pure module */
